// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".socialMediaButton_social_button__ZB-7k {\n  text-decoration: none;\n  color: inherit;\n}\n\n.socialMediaButton_telegram__nW3WP:hover path {\n  fill: #59bfeb;\n  transition: 0.3s linear;\n}\n\n.socialMediaButton_vk__JckF5:hover path {\n  fill: #357eeb;\n  transition: 0.3s linear;\n}\n\n.socialMediaButton_x__oGc98:hover path {\n  fill: #332f36;\n  transition: 0.3s linear;\n}\n\n.socialMediaButton_youtube__iNL71:hover path:nth-child(3) {\n  transition: 0.3s linear;\n  fill: #ff0000;\n}\n\n.socialMediaButton_link__siG4V:hover path {\n  fill: url(#gradient_link);\n  transition: 0.3s linear;\n}\n\n.socialMediaButton_instagram__1hDHH:hover path {\n  stroke: url(#gradient_instagram);\n  transition: 0.3s linear;\n}", "",{"version":3,"sources":["webpack://./src/components/SocialMediaButton/socialMediaButton.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAGE;EACE,uBAAA;EACA,aAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,uBAAA;AADF;;AAIA;EACE,gCAAA;EACA,uBAAA;AADF","sourcesContent":[".social_button {\n  text-decoration: none;\n  color: inherit;\n}\n\n.telegram:hover path {\n  fill: #59bfeb;\n  transition: 0.3s linear;\n}\n\n.vk:hover path {\n  fill: #357eeb;\n  transition: 0.3s linear;\n}\n\n.x:hover path {\n  fill: #332f36;\n  transition: 0.3s linear;\n}\n\n.youtube:hover {\n  path:nth-child(3) {\n    transition: 0.3s linear;\n    fill: #ff0000;\n  }\n}\n\n.link:hover path {\n  fill: url(#gradient_link);\n  transition: 0.3s linear;\n}\n\n.instagram:hover path {\n  stroke: url(#gradient_instagram);\n  transition: 0.3s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social_button": "socialMediaButton_social_button__ZB-7k",
	"telegram": "socialMediaButton_telegram__nW3WP",
	"vk": "socialMediaButton_vk__JckF5",
	"x": "socialMediaButton_x__oGc98",
	"youtube": "socialMediaButton_youtube__iNL71",
	"link": "socialMediaButton_link__siG4V",
	"instagram": "socialMediaButton_instagram__1hDHH"
};
export default ___CSS_LOADER_EXPORT___;
